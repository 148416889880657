import { render, staticRenderFns } from "./link_manage.vue?vue&type=template&id=deadbd30&scoped=true&"
import script from "./link_manage.vue?vue&type=script&lang=js&"
export * from "./link_manage.vue?vue&type=script&lang=js&"
import style0 from "./link_manage.vue?vue&type=style&index=0&id=deadbd30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "deadbd30",
  null
  
)

export default component.exports