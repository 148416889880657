<template>
  <div v-if="visible">
    <div class="device">

      <span style="line-height: 30px;">设备名称：</span>
      <span style="line-height: 30px;width: 200px;display:inline-block; margin-right: 20px;">{{device.title?device.title:device.uid}}</span>
      <span style="line-height: 30px;margin-right: 20px;">传感器:</span>

      <el-select v-model="field_val" placeholder="请选择" size="small" style="margin-right: 20px;">
        <el-option v-for="(item,index) in sensors" :key="index" :label="item.title" :value="index">
          <span style="float: left;font-size: 13px">{{ item.title }}<span style="color: #8492a6; font-size: 12px">({{ item.unit }})</span></span>

        </el-option>
      </el-select>

      <el-button type="primary" @click="commitAdd" size="small">
        <i class="el-icon-circle-plus-outline"></i>添加
      </el-button>
    </div>
    <div v-if="list.length != 0" class="content" v-loading="loading" element-loading-text="加载中">
      <div style="margin-bottom:10px;color: red" v-if="device.model == 'dmWater'">注意此处内涝设备的单位为米</div>
      <div v-for="(item, index) in list" :key="index">
        {{ item.title }}<br /><br />
        <!-- <el-button type="info" size="small">{{ item.size }}</el-button> -->
        <el-radio-group v-model="item.size" size="small" @change="sizeChange(index,item.size)">
          <el-radio label="1" border style="margin-right:0 !important">大于</el-radio>
          <el-radio label="2" border>小于</el-radio>
        </el-radio-group>
        <el-input v-model="item.value" size="small"></el-input>
        <template v-if="actions.io">
          <el-checkbox v-model="item.io" size="small" label="IO输出" border></el-checkbox>
          <i class="el-icon-link"></i>
        </template>
        <template v-if="actions.aa">
          <el-checkbox v-model="item.aa" size="small" label="声音报警" border></el-checkbox>
          <i class="el-icon-bell"></i>
        </template>
        <template v-if="actions.sms">
          <el-checkbox v-model="item.sms" size="small" label="短信报警" border></el-checkbox>
          <i class="el-icon-chat-dot-square"></i>
        </template>
        <el-button type="danger" icon="el-icon-delete" size="small" @click="deleteData(index)" circle></el-button>

        <el-divider></el-divider>
      </div>

    </div>
    <div v-else class="content" v-loading="loading" element-loading-text="加载中">
      <span>此设备未设置联动</span>
      <el-divider></el-divider>
    </div>

    <!-- <el-dialog title="传感器" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-radio v-model="radio" label="1">水位</el-radio>
      <el-radio v-model="radio" label="2">雨量</el-radio>
      <br /><br />
      <el-radio v-model="size" label="1">大于</el-radio>
      <el-radio v-model="size" label="2">小于</el-radio>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitAdd">确 定</el-button>
      </span>
    </el-dialog> -->

    <div class="btn">
      <el-button type="success" size="small" @click="onSubmit">保存</el-button>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      radio: '1',
      size: '1',
      visible: true,
      title: '',
      uid: '',
      list: [],
      txt: '',
      checked: true,
      device: {
        uid: '',
        model: '',
        title: '',
      },
      field_val: "",
      fields: {},
      sensors: [],
      actions: [],
      loading: true,
      timeCount: 0
    }
  },
  watch: {
    '$store.state.curTreeData': {
      handler(val) {
        if (val[0].type == "device") {
          let [{ uid, title, model }] = val;
          this.device = { uid, title, model };
          this.setLoadingtimeOut("start")//开始计时

          this.getFieldsData(model)
          this.getData(uid)
          this.uid = uid
          this.field_val = ""
        }
      }
    }
  },
  created() {
    if (this.$store.state.treeData.length) {
      let obj = this.$store.state.treeData[0].children

      let path = '全部设备' + ' > ' + obj[0].title + ' > ' + obj[0].children[0].title
      setTimeout(() => {
        this.$parent.$parent.setNodePath(path);
      }, 500)
      let first = obj[0].children[0]
      if (first.uid == undefined) {   //防止设备树层级过多
        first = obj[0].children[0].children[0]
      }
      this.uid = first.uid
      let { uid, title, model } = first;
      this.device = { uid, title, model };
      this.getFieldsData(model)
      this.getData(uid)
    }
  },

  mounted() {
    //选择的设备
    this.ws.addCallback('devicesAck', (res) => {
      let obj = this.$store.state.treeData[0].children
      let path = '全部设备' + ' > ' + obj[0].title + ' > ' + obj[0].children[0].title
      this.$parent.$parent.setNodePath(path);

      let first = obj[0].children[0]
      if (first.uid == undefined) {   //防止设备树层级过多
        first = obj[0].children[0].children[0]
      }

      this.uid = first.uid
      let { uid, title, model } = first;
      this.device = { uid, title, model };
      this.getFieldsData(model)
      this.getData(uid)
    })

    this.user = this.common.cGetUser();

    //联动返回值
    this.ws.addCallback("alarmConfigAck", (res) => {
      this.list = []

      if (res.error == '-1') {
        this.$message.error('获取数据失败!');
      } else {
        let obj = JSON.parse(res.config)

        obj.cases && obj.cases.forEach(item => {
          let list = {}
          let sensorsdata = this.sensors.filter(e => e.sensors == item.sensor)
          list.title = sensorsdata[0].title + "(" + sensorsdata[0].unit + ")"//item.sensor == 'water' ? '水位(米)' : '雨量(毫米)'
          list.name = item.sensor
          list.value = item.con.slice(1)
          list.size = item.con.slice(0, 1) == '>' ? '1' : '2'
          list.flag = item.con.slice(0, 1)
          list.io = item.io == '1' ? true : false
          list.aa = item.aa == '1' ? true : false
          list.sms = item.sms == '1' ? true : false

          this.list.push(list)

        })
      }
      this.setLoadingtimeOut("close")
      this.loading = false
    })
  },

  methods: {
    getData(val) {
      var json = {
        "cmd": "alarmConfig",
        "uid": val
      }

      this.ws.send(json)

    },
    sizeChange(index, size) {
      this.list[index].flag = size == '1' ? '>' : '<'
    },

    //获取模型内的联动数据
    getFieldsData(model) {
      //可以联动的字段
      let sensors = this.parseProfile.alarmSensors(model)
      console.log("sensors", sensors);

      this.sensors = {}
      this.sensors = sensors
      //可以设置报警的方式
      this.actions = this.parseProfile.alarmActions(this.device.model)
      // console.log(this.actions);
    },
    onSubmit() {
      //{"cases":[{"sensor":"water","con":">1.1","sms":1},{"sensor":"rainFall","con":">5.0","sms":1}]}
      // console.log(this.list)
      let cases = []
      this.list.forEach(item => {
        let obj = {};
        obj.sensor = item.name;

        obj.con = item.flag + item.value;
        if (item.io) {
          obj.io = 1
        }
        if (item.aa) {
          obj.aa = 1
        }
        if (item.sms) {
          obj.sms = 1
        }

        cases.push(obj)
      })

      let objData = { cases: cases }
      var json = {
        "cmd": "setAlarmConfig",
        "uid": this.uid,
        "config": objData
      }

      this.ws.send(json)

      this.ws.addCallback("setAlarmConfigAck", (res) => {
        // console.log(res)
        if (res.error == '-1') {
          this.$message({
            showClose: true,
            message: res.desc,
            type: 'error'
          });
        } else {
          this.$message({
            showClose: true,
            message: '保存成功!',
            type: 'success'
          });
        }
      })

    },
    deleteData(index) {
      this.list.splice(index, 1)
    },
    commitAdd() {
      // console.log(this.field_val);
      if (this.field_val === "") {
        this.common.cToast(this, "请选择传感器后再添加", 'warning');
        return
      }

      if (this.list.length >= 32) {
        this.$message({
          showClose: true,
          message: '已达到最大条数!',
          type: 'warning'
        });
        return
      }

      this.list.push({
        title: this.sensors[this.field_val].title + "(" + this.sensors[this.field_val].unit + ")",//this.radio == 1 ? '水位(米)' : '雨量(毫米)',
        name: this.sensors[this.field_val].sensors,//this.radio == 1 ? 'water' : 'rainFall',
        value: '',
        size: "1",
        flag: '>',
        io: false,
        aa: false,
        sms: false
      })

      this.dialogVisible = false
    },
    handleClose(done) {
      done();
    },
    setLoadingtimeOut(val) {
      let state = ""
      clearTimeout(this.timeCount);
      if (val == "start") {
        state = setTimeout(() => {
          if (this.loading) {
            this.common.cToast(this, "获取数据失败，请刷新后重试");
            this.loading = false
          }
        }, 5000)
        this.timeCount = state
      } else {
        clearTimeout(state);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.device {
  font-size: 14px;
}
.content {
  margin-top: 20px;
  min-height: 145px;
}
.el-input {
  width: 60px;
  margin: 0 15px;
}
.el-checkbox:last-of-type {
  margin-right: 20px;
}
[class^="el-icon-"],
[class*=" el-icon-"] {
  margin: 0 16px 0 6px;
}
.el-checkbox {
  margin-right: 0px !important;
}
</style>